<!-- 
@Author ——ZGM
@Date ——2022年
-->
<template>
  <div class="contactUs">
    <div class="banner">
      <!-- <img src="../../assets/images/contactUs/contactUsText.png" alt="#" /> -->
    </div>
    <!-- 联系我们 -->
    <div class="centerContent">
      <div class="centerTitle">
        <p>联系我们</p>
        <p>CONTACT US</p>
      </div>

      <!-- 图案部分 -->
      <ul class="contactWay" ref="pronbit">
        <li v-for="(item, index) in contactWay" :key="index">
          <div class="containerLi" :class="{ activeRight: isFixed }">
            <!-- 图标 -->
            <div class="icon">
              <img :src="item.icon" alt="" />
            </div>
            <p class="oneWay">{{ item.title_1 }}</p>
            <p class="numTwoP">
              <b> {{ item.title_2 }}</b>
            </p>
            <p class="numTwoP" v-if="item.title_3">
              <b> {{ item.title_3 }}</b>
            </p>
          </div>
        </li>
      </ul>

      <!-- 地图部分 -->
      <div class="mapContainer" ref="map_move">
        <div id="my_map" :class="{ mapmove: isMap }"></div>
      </div>
    </div>

    <!-- 了解更多 -->
    <div class="learnMore" ref="moreMove">
      <div class="content">
        <!--  :class="{ moreMoveText: moreMove }" -->
        <div class="textContent" :class="{ moreMoveText: moreMove }">
          <div class="left">连接改变生活 • LINK FOR GOOD</div>
          <div class="right">
            <el-button @click="jumpLearnMore">了解更多</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contactWay: [
        {
          icon: require("../../assets/images/contactUs/phone.png"),
          title_1: "客服热线",
          title_2: "028-8518 6891",
        },
        {
          icon: require("../../assets/images/contactUs/qq.png"),
          title_1: "在线客服QQ",
          title_2: "16180319",
        },
        {
          icon: require("../../assets/images/contactUs/email.png"),
          title_1: "邮箱",
          title_2: "support@ilingke.cn",
        },
        {
          icon: require("../../assets/images/contactUs/address_fill.png"),
          title_1: "地址",
          title_2: "四川省成都市天府大",
          title_3: "道北段1700号",
        },
      ],
      map: null,
      oldMapPonit: [103.978416, 30.635498],
      oldZoom: 16,
      infoWindow: "",
      connectMe: null,
      isFixed: false,
      numberOne: true, //判断是不是第一次滚动到这里或者是刷新后滚动到这里
      isMap: false,
      mapTop: null,
      moreMove: false,
      moreTop: null,
    };
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    this.initMap();
    this.getTop();
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 获取各元素距离顶部的距离
    getTop() {
      this.connectMe = this.$refs.pronbit.getBoundingClientRect().top;
      this.mapTop = this.$refs.map_move.getBoundingClientRect().top;
      this.moreTop = this.$refs.moreMove.getBoundingClientRect().top;
    },

    // 元素滚动监听
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop; // 滚动条偏移量

      if (
        scrollTop > this.connectMe - 653 ||
        (scrollTop < this.connectMe + 100 && this.numberOne)
      ) {
        this.isFixed = true;
      } else {
        if (!this.numberOne) {
          this.isFixed = true;
        }
      }

      if (this.mapTop - 830 < scrollTop && !this.isMap) {
        this.isMap = true;
      }

      if (scrollTop > 500) {
        this.moreMove = true;
      }

      this.numberOne = this.isFixed ? false : true;
    },

    initMap() {
      //定义地图
      this.map = new AMap.Map("my_map", {
        // minZoom: 12,
        // maxZoom: 18,
        resizeEnable: true,
        zoom: this.oldZoom, //地图显示的缩放级别
        center: this.oldMapPonit,
        zooms: [10, 13],
        mapStyle: "amap://styles/macaron", //地图样式
      });

      this.map.plugin(["AMap.ToolBar"], () => {
        //加载工具条
        let tool = new AMap.ToolBar({ position: "RT" });
        this.map.addControl(tool);
      });

      // 添加marker
      let marker = new AMap.Marker({
        position: new AMap.LngLat(...this.oldMapPonit), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: "北京",
        color: "#FC5634",
      });
      // 将创建的点标记添加到已有的地图实例：
      this.map.add(marker);

      // 构建信息窗口
      let boxContent = `<div class='mapList' style='height:122px;width:282px;background:#fff;padding:18px 21px 18px 12px;box-sizing:border-box'>
          <p class='title' style='color:#333;font-size:14px';margin-bottom:5px;font-weight:700;font-family: 'Microsoft YaHei';>
           <b>成都领氪科技有限公司</b> <span class='btn' style='position:absolute;top:6px;right:8px;z-index:9999;cursor: pointer;'><i style="font-size:16px;" class='el-icon-close'></i></span> </p>
        <div class='more' style='color:#333;font-size:14px;margin:10px 0 0px'>
         中国(四川)自由贸易试验区成都高新区天府大道北段1700号环球中心E3区14层1410
        </div>
           </div>`;

      let infoWindow = new AMap.InfoWindow({
        isCustom: true, //使用自定义窗体
        content: boxContent,
        offset: new AMap.Pixel(16, -45),
      });
      infoWindow.open(this.map, marker.getPosition());

      //绑定点击方法
      AMap.event.addDomListener(
        document.getElementById("my_map"),
        "click",
        (e) => {
          // DOM 被点击时触发，ev 为原生事件
          if (e.target.className === "el-icon-close") {
            infoWindow.close();
          }
        }
      );

      marker.on("click", () => {
        infoWindow.open(this.map, marker.getPosition());
      });
    },
    jumpLearnMore() {
      // 跳转到关于领氪
      sessionStorage.setItem("path", "4");
      this.$router.push("/home/aboutLink");
    },
  },
};
</script>

<style lang="less" scoped>
.contactUs {
  .banner {
    height: 500px;
    background: url("../../assets/images/contactUs/banner.jpg") no-repeat center;
    background-size: cover;
  }

  .centerContent {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    border: 1px solid #fff;
    position: relative;
    overflow: hidden;

    .centerTitle {
      height: 266px;
      width: 1200px;
      box-sizing: border-box;
      padding: 85px 0 110px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: absolute;
      right: -540px;
      animation: connectMe 1s ease forwards;
      p:first-child {
        color: #000;
        font-size: 30px;
        padding: 0;
        text-align: center;
        font-family: "Microsoft YaHei";
        font-weight: 700;
      }

      p:last-child {
        color: #808080;
        font-size: 18px;
        padding: 0;
        text-align: center;
        font-family: "Microsoft YaHei";
      }
    }

    .contactWay {
      height: 216px;
      list-style: none;
      display: flex;
      justify-content: space-between;
      margin-bottom: 120px;
      margin-top: 216px;
      padding-top: 50px;
      li {
        position: relative;
        height: 216px;
        width: 216px;
        overflow: hidden;
        .containerLi {
          height: 216px;
          width: 216px;
          border: 1px solid #ddd;
          border-radius: 50%;
          box-sizing: border-box;
          position: absolute;

          right: -216px;
          // animation: connectMeIcon 1s ease forwards;
        }
        .icon {
          margin: 0 auto;
          height: 68px;
          width: 179px;
          padding-bottom: 3px;
          border-bottom: 1px solid #ddd;
          display: flex;
          margin-top: 40px;
          margin-bottom: 12px;
          img {
            margin: 0 auto;
            height: 68px;
            width: 68px;
          }
        }
        .oneWay {
          height: 26px;
          line-height: 26px;
          text-align: center;
          color: #212121;
          font-family: "Microsoft YaHei";
          font-size: 14px;
        }

        .numTwoP {
          height: 26px;
          line-height: 26px;
          color: #000;
          font-family: "Microsoft YaHei";
          font-size: 14px;
          max-width: 137px;
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
        }
      }
      li:nth-child(2) {
        .icon {
          margin-top: 30px;
          padding-bottom: 10px;
        }
      }
    }

    .mapContainer {
      position: relative;
      overflow: hidden;
      width: 1200px;
      height: 300px;
      margin-top: 170px;
      #my_map {
        width: 1200px;
        height: 300px;
        margin: 0 auto;
        position: absolute;
        right: -1200px;
        // animation: mapMove 1s ease forwards;
      }
    }
  }

  .learnMore {
    margin-top: 62px;
    height: 150px;
    width: 100%;
    background: #2f4052;

    .content {
      width: 63%;
      height: 150px;
      position: relative;
      margin: 0 auto;
      overflow: hidden;
      // border: 1px solid red;
    }
    .textContent {
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: space-between;
      padding-right: 5.5%;
      box-sizing: border-box;
      position: absolute;
      right: -80em;

      .left {
        height: 150px;
        line-height: 150px;
        color: #fff;
        font-family: "Microsoft YaHei";
        font-size: 20px;
      }
      .right {
        height: 150px;
        display: flex;
        align-items: center;
        .el-button {
          background: rgba(1, 126, 176, 1);
          cursor: pointer;
          padding: 0;
          width: 164px;
          height: 50px;
          line-height: 50px;
          color: #fff;
          text-align: center;
          border: none;
          border-radius: 25px;
          span {
            font-family: "Microsoft YaHei";
            font-size: 16px;
          }
        }
      }
    }
  }
  .activeRight {
    animation: connectMeIcon 1s ease forwards;
  }

  .mapmove {
    animation: mapMove 0.7s ease forwards;
  }

  .moreMoveText {
    animation: Link 1.5s ease forwards;
  }
  // 动画--联系我们
  @keyframes connectMe {
    form {
      position: absolute;
      right: -540px;
    }
    to {
      position: absolute;
      right: 0;
    }
  }

  // 图标--联系我们
  @keyframes connectMeIcon {
    form {
      right: -216px;
    }
    to {
      right: 0;
    }
  }

  // 地图动画
  @keyframes mapMove {
    from {
      right: -1200px;
    }
    to {
      right: 0;
    }
  }

  // 了解更多
  @keyframes Link {
    from {
      right: -80em;
    }
    to {
      right: 0;
    }
  }
}
</style>
